
  import { Component, Mixins, Prop, Emit, Watch } from "vue-property-decorator";
  import { ValidationProvider } from "vee-validate";
  import { HasProperyPart } from "@/mixins/has-property-part";
  import { FormMoney, FormInput } from "@/components/forms";

  @Component({
    components: {
      InputRadio: () => import("@/components/forms/property-radio.vue"),
      FormMoney,
      FormInput,
      ValidationProvider,
    },
  })
  export default class PropertyPartInstallationHeating extends Mixins(HasProperyPart) {
    @Prop({ required: true }) value?: PropertyEquimentSpecialized;

    localValue: PropertyEquimentSpecialized = {
      has_solar_panels: null,
      solar_panels: null,
      solar_panel_certificate: null,
      solar_water_heater: null,
      solar_panel_certificates_per_year: null,
      solar_panel_amount_per_certificate: null,
      water_softener: null,
      rainwater_collection: {
        water_wells: null,
        water_well_volume_liters: [],
        toilet: null,
        outdoor_faucet: null,
        indoor_faucet: null,
        washing_machine: null,
      },
      septic_tank_available: null,
      septic_tank_volume: null,
    };

    mounted() {
      // TODO: should be solved with a migration not in the front-end
      this.localValue.has_solar_panels = (this.localValue.solar_panels ?? 0) > 0;
    }

    resetValues() {
      this.localValue.solar_panels = 0;

      this.handleInput();
    }

    toggleArrayProperty(property: string[], value: string) {
      this.toggleArrayPropertyValue(property, value);

      this.handleInput();
    }

    handleWaterWellsChanged(event: string) {
      const intValue = parseInt(event) || 0;
      for (let i = 0; i <= intValue; i++) {
        this.localValue.rainwater_collection.water_well_volume_liters[i] = this.localValue.rainwater_collection.water_well_volume_liters[i] || 0;
      }
      this.localValue.rainwater_collection.water_well_volume_liters = this.localValue.rainwater_collection.water_well_volume_liters.splice(0, intValue);

      this.handleInput();
    }

    @Emit("input")
    handleInput() {
      return this.localValue;
    }

    @Watch("value", { immediate: true })
    valueChanged(newValue: PropertyEquimentSpecialized) {
      this.localValue = newValue;
    }
  }
